import firstBenefit from "@images/benefit1.svg"
import thirdBenefit from "@images/benefit3.svg"
import hat from "@images/hat.svg"
import tax from "@images/tax.svg"
import golocker from "@images/goLocker-logo.png"
import lulu from "@images/lulu.png"

export const HEADER_BACKGROUND_IMAGE =
  "https://files.impressit.io/fintech2_min_d292be7155.png"
export const HEADER_DESCRIPTION =
  "Impressit delivers tailored fintech application development services. With collaborative partnerships, we transform ambitious ideas into exceptional fintech products."
export const HEADER_TITLE = "Fintech software development services"
export const HEADER_BUTTON_TEXT = "Let's chat"
export const MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "Fintech app development services"
export const MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION =
  "We provide the following fintech software development services:"

export const FAQ_SECTION_ITEMS = [
  {
    title: "What are the advantages of outsourcing the development of my fintech app?",
    text: "Outsourcing development offers benefits such as access to a skilled and experienced development team, cost savings compared to hiring an in-house team, faster time-to-market, scalability, and the ability to focus on core business activities.",
  },
  {
    title: "What measures should I take to ensure effective communication with my outsourcing team?",
    text: "To ensure effective communication, establish clear channels of communication and regular meeting schedules, leverage project management tools, maintain open and transparent communication, provide detailed documentation, and designate a dedicated point of contact for both teams.",
  },
  {
    title: "How can I ensure the security and confidentiality of my fintech app's intellectual property and sensitive data when outsourcing?",
    text: "Protecting intellectual property and sensitive data involves signing comprehensive non-disclosure agreements (NDAs) with the outsourcing partner, implementing secure data transfer protocols, defining data access and storage policies, and regularly monitoring and auditing security practices.",
  },
  {
    title: "How can I ensure the security of user data and transactions in my fintech app?",
    text: "To ensure security, you should implement measures like data encryption, secure authentication protocols, regular security audits, adherence to compliance standards (e.g., PCI-DSS), and secure connections through HTTPS.",
  },
  {
    title: "What technologies and frameworks are commonly used in fintech app development?",
    text: "Popular technologies and frameworks for fintech app development include React Native, Flutter, Java, Swift, RESTful APIs, cloud infrastructure (such as AWS or Azure), and data storage solutions like MongoDB or PostgreSQL.",
  },
  {
    title: "How can I integrate payment gateways and ensure smooth financial transactions?",
    text: "Integrating with reputable payment gateways like Stripe, PayPal, or Braintree can facilitate seamless and secure financial transactions. Follow their developer documentation and utilize their SDKs or APIs to integrate payment functionalities into your app.",
  },
  {
    title: "What compliance regulations should I consider when developing a fintech app?",
    text: "Compliance requirements may vary depending on your app's functionality and the regions you operate in. Common regulations to consider include GDPR, PSD2, AML/KYC procedures, data privacy laws, and financial industry regulations.",
  },
]

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Dedicated developers for fintech companies",
    text: "By leveraging our dedicated fintech software development teams, you gain the advantage of collaborating with industry specialists who possess extensive expertise in cutting-edge technologies. This not only empowers you with up-to-date knowledge but also helps you achieve significant cost savings by eliminating the necessity to recruit and train an in-house staff.",
  },
  {
    title: "Custom fintech software development",
    text: "In the fintech industry, customized software development offers specialized solutions that optimize operations, enhance productivity, and seamlessly align with evolving market dynamics. This tailored approach fosters distinctiveness, strategic edge, and superior customer experiences. Ultimately, software development in fintech plays a pivotal role in driving sustainable success, effectively realizing business objectives.",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT =
  "Let's discuss our potential partnership"

export const MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT = "Contact us"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "We can help you with:"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Discovery and planning",
    text: "In the discovery phase of fintech software development, our team conducts a thorough analysis of your idea, transforming it into a well-defined product vision and an elaborate development plan.",
  },
  {
    title: "AI-driven transformation",
    text: "AI holds immense potential in the fintech sector, offering solutions such as fraud detection, algorithmic trading, and personalized financial insights. These AI-driven applications enhance efficiency, security, and innovation in fintech, driving the industry forward."
  },
  {
    title: "Mobile app development",
    text: "Our team of experienced developers and designers will work closely with you to understand your unique requirements and transform them into a cutting-edge mobile app that captures your brand essence. From seamless user experience to robust security features, we ensure that your app stands out in the competitive fintech market.",
  },
  {
    title: "Web app development",
    text: "Our skilled team of developers and designers will collaborate closely with you to understand your unique requirements and transform them into a cutting-edge web app that embodies your brand identity.",
  },
  {
    title: "Payment & billing solutions",
    text: "Our solutions encompass secure mobile payments, billing, and invoicing, designed with a user-friendly interface. With a strong emphasis on compliance and security, our software ensures adherence to key industry requirements, providing a fully secure environment for financial transactions.",
  },
  {
    title: "Cloud AWS solutions",
    text: "As a trustworthy partner in fintech software development, we provide exceptional cloud solutions using Amazon Web Services. Our goal is to support your business's inevitable growth and mitigate any risks that may arise.",
  },
]

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT = "Contact us";

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT = "";

export const WHY_SCHOOSE_SECTION_TITLE = "Why choose Impressit?";

export const WHY_CHOOSE_ITEMS = [
  {
    title: "Full commitment",
    iconFromMarkupPath: firstBenefit,
    description:
      "At Impressit, we are equally dedicated to the success of your business and committed to supporting you throughout the entire development process.",
  },
  {
    title: "Time & costs savings",
    iconFromMarkupPath: tax,
    description:
      "Partnering with Impressit for quality development is crucial in avoiding future issues. Our experienced developers create top-notch products with comprehensive maintenance support for the lifetime of your software. Don't settle for less; prioritize security, functionality, and up-to-date technology with Impressit.",
  },
  {
    title: "Reduced Risks",
    iconFromMarkupPath: thirdBenefit,
    description:
      "While it may be tempting to hire freelance developers who offer lower hourly rates, it could end up costing you more in terms of security concerns, incomplete documentation, and subpar code.",
  },
  {
    title: "Experience",
    iconFromMarkupPath: hat,
    description:
      "It is a top priority for us to ensure that you are updated on all aspects of your software. We are delighted to provide our experience and insight to support you as you go through the production journey.",
  },
]

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch";

export const SECOND_CATCH_UP_SECTION_DESCRIPTION = "Leave us a note and we will contact you within 24 hours";